.step-tracker {
  text-align: center;
  margin-top: 5px;

  .step-tracker-dot {
    height: 8px;
    width: 8px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 3px;

    &--current {
      background-color: #555;
    }
  }
}