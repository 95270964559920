.submit-complete {
  text-align: center;
  margin: 35vh 5% auto 5%;

  .submit-complete-icon {
    max-width: 36px;
    max-height: 36px;
    fill: #1ebb00;
  }

  .submit-complete-title {
    font-weight: bold;
    margin: 10px 0;
  }
}
