.enrollment-app {
  max-width: 600px;
  display: flex;

  .enrollment-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .enrollment-form-content {
    flex: 1;
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 30px 0;
  }

  &__title-image {
    width: 20%;
    margin-right: 20px;
  }

  .field {
    width: 100%;
    margin: 10px 0;
  }

  .single-check-field {
    margin: 0;
  }

  .text-input {
    width: 100%;
  }

  .dropdown-input {
    width: 100%;
  }

  .font-small {
    font-size: 10px;
  }

  .icon-grey {
    fill: #babdbf;
  }

  .radio-group-full-width {
    width: 100%;
  }

  .radio-error {
    border-color: #ff0000;
  }

  .field-group-title {
    margin: 40px 0 10px 0;
    font-weight: bold;
  }

  .eula-text {
    white-space: pre-line;
  }
}
