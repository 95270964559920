.loading {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 40vh;
}

.loading-text {
  margin: 12px;
  color: #6a6b6d;
}

.loading-overlay {
  min-height: 100%;
  background-color: rgba(255,255,255,0.8);
  position: fixed;
  z-index: 1000;
  width: 600px;
  margin-left: -30px;
}
