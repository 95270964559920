@import 'styles/EnrollmentForm';
@import 'styles/EntrollmentFormStepTracker';
@import 'styles/EnrollmentFormFooter';
@import 'styles/Loading';
@import 'styles/Error';
@import 'styles/CompletedForm';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');


// Overrides react bootstrap
div[class^="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.row {
  padding-left: 10px;
  padding-right: 10px;
}
