.unknown-error {
  text-align: center;
  padding-top: 15%;

  .error-image {
    max-width: 40%;
  }

  .error-title {
    margin-top: 20px;
    font-weight: bold;
  }

  .error-sub {
    margin-top: 10px;
  }

  .spot-link:hover {
    color: #00a7b5;
  }
}
