.enrollment-form-footer {
  background-color: #eee;
  position: sticky;
  bottom: 0;
  padding: 5px 10px 5px 10px;
  z-index: 10;

  .footer-logo {
    float: right;
    max-width: 130px;
    padding-top: 5px;
    padding-right: 10px;
  }

  .footer-action-row {
    text-align: center;
    margin-top: 15px;
  }
}
