@import 'variables';

@import 'spot';
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/scss/main';

//Layout

// Pages

// Components
@import 'components/index';
@import 'common';

#root {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    min-height: 100%;
    display: flex;

    > * {
        display: flex;
    }
}

.base-layout {
    display: flex;
    max-width: 600px;
}

.container-fluid {
    display: flex;
}

.vertical-align {
    display: flex;
    flex-direction: row;
}

.vertical-align > [class^='col-'],
.vertical-align > [class*=' col-'] {
    display: flex;
    align-items: center; /* Align the flex-items vertically */
    justify-content: center; /* Optional, to align inner flex-items
                              horizontally within the column  */
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
    border: 0;
}

.navbar {
    z-index: 11;
    .navbar-collapse.collapse:not(.show) {
        .navbar-nav.links {
            display: none;
        }
    }

    .spot-di {
        margin-right: 15px;
    }

    .spot-di__header {
        display: flex;
        align-items: center;
    }
}
.connect-page-header {
    position: sticky;
    top: 0;
    z-index: 200;
    height: 71px;
}

.connect-page-header-line {
    background-color: #00a7b5;
    height: 5px;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
    display: none;
}
@media (min-width: 768px) {
    .sidebar {
        position: fixed;
        top: 71px;
        height: calc(100vh - 62px);
        width: 240px;
        z-index: 10;
        display: block;
        padding: 24px;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        background-color: #f4f6f7;
        border-right: 1px solid #eee;
    }
}

/* Sidebar navigation */
.nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
}
.nav-sidebar > li > a {
    padding-right: 20px;
    padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca;
}

/*
 * Main content
 */

@media (min-width: 768px) {
    // .main {
    //  padding-right: 40px;
    //  padding-left: 40px;
    // }
}
.main {
    padding-left: 240px;

    &.is-collapsed {
        padding-left: 62px;
    }

    #connect-page-content .container-fluid {
        padding-right: 24px;
    }

    .page-header {
        margin-top: 0;
    }
}

.coming-soon--container {
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    padding: 10%;
}

.tos-header {
    font-weight: bold;
    margin-bottom: 25px;
}

.tos-idexx-logo {
    height: 32px;
    width: 220px;
}

.default-error-page {
    .writing {
        width: 320px;
        height: 200px;
        background-color: #3f3f3f;
        border: 1px solid #bbbbbb;
        border-radius: 6px 6px 4px 4px;
        position: relative;
    }

    .writing .topbar {
        position: absolute;
        width: 100%;
        height: 12px;
        background-color: #f1f1f1;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .writing .topbar div {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin: 3px;
        float: left;
    }

    .writing .topbar div.green {
        background-color: #60d060;
    }
    .writing .topbar div.red {
        background-color: red;
    }
    .writing .topbar div.yellow {
        background-color: #e6c015;
    }

    .writing .code {
        padding: 15px;
    }

    .writing .code ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .writing .code ul li {
        background-color: #9e9e9e;
        width: 0;
        height: 7px;
        border-radius: 6px;
        margin: 10px 0;
    }

    .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-right: 16px;
        padding-left: 16px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
    }

    .stack-container {
        position: relative;
        width: 420px;
        height: 210px;
        -webkit-transition: width 1s, height 1s;
        transition: width 1s, height 1s;
    }

    .pokeup {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .error {
        width: 400px;
        padding: 40px;
        text-align: center;
    }

    .error h1 {
        font-size: 125px;
        padding: 0;
        margin: 0;
        font-weight: 700;
    }

    .error h2 {
        margin: -30px 0 0 0;
        padding: 0px;
        font-size: 47px;
        letter-spacing: 12px;
    }

    .perspec {
        -webkit-perspective: 1000px;
        perspective: 1000px;
    }

    @media screen and (max-width: 1200px) {
        .container {
            -webkit-transform: scale(0.85);
            transform: scale(0.85);
        }
    }

    @media screen and (max-width: 768px) {
        .container {
            -webkit-transform: scale(0.75);
            transform: scale(0.75);
        }
    }

    @media screen and (max-width: 480px) {
        .container {
            -ms-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
            -webkit-box-align: inherit;
            -ms-flex-align: inherit;
            align-items: inherit;
        }
    }

    @media screen and (max-width: 320px) {
        .container {
            -webkit-transform: scale(0.6);
            transform: scale(0.6);
        }
    }
}
